import React from "react"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
import DatabseUploadComponent from '../components/databaseupload/DatabaseUpload';
import BrowserBanner from "../components/reusable/BrowserBanner";

const DatabaseUpload = () => (
  <>
  <BrowserBanner />
  <Layout mode={true}>
    <SEOComponent title="Amber by inFeedo | Database Upload" />
    <DatabseUploadComponent/>
  </Layout></>
)

export default DatabaseUpload;