import React, { Component } from 'react';
import axios from '../../helper/Axios';
import classnames from 'classnames';
import {looseFocus} from '../../helper/looseFocus';
import { NotificationManager } from 'react-notifications';
import infeedo_logo from '../../../static/images/infeedo.svg';
import { getServiceRegion } from '../../helper/getApiBase';
import handleException from '../../helper/errorHandling';

export default class DatabaseUpload extends Component {
    
    state = {
        email: '',
        loading: false
    }

    // On change input values map to state
    // -----------------------------------
    onChange = e => this.setState({ [e.target.name]: e.target.value });

    sendMagicLink = async (e) => {
        const { email } = this.state;
        if(e){
            e.preventDefault();
        }
        looseFocus();
        this.setState({loading: true});
        try {

            sessionStorage.removeItem("current_region_url");
            await getServiceRegion(email,"dashboard");
            const response = await axios.post('/v1/users/send_magic_link?is_accessible=true', {email});
            if(response.status === 200){
                NotificationManager.success(`A Magic link has been sent to ${email} which is valid for 15 minutes only.`);
                this.setState({email: ''});
            }
        } catch (error) {
            handleException(error, 'Reset Failed');
        }
        this.setState({loading: false});
    }

    render() {
        const { email, loading } = this.state;
        return (
            <div className="column is-5 mar-auto pd-25">
                <figure className="app-logo">
                    <img src={infeedo_logo} width="130px" alt="amber logo"/>
                </figure>
                <div className="columns is-mobile">
                    <div className="column no-pd-bottom"><h1 className="login-heading no-margin-padding-bottom">Database Upload</h1></div>
                </div>
                <p className="font-14">Using this feature you can add, edit and delete users fom Amber's database</p>
                <br/>
                <form onSubmit={this.sendMagicLink}>
                    <div className="field">
                        <p className="control">
                            <input onChange={this.onChange} value={email} name="email" className="input" type="email" placeholder="Enter Work Email ID" id="email" required autoFocus/>
                        </p>
                    </div>
                    <div className="field is-mobile pd-top-12">
                        <p className="control has-text-right">
                            <button type="submit" className={classnames('button is-link font-14', {'is-loading': loading})} disabled={!email.length}>
                            &nbsp;&nbsp;Email Magic Link&nbsp;&nbsp;
                            </button>
                        </p>
                    </div> 
                </form>
            </div>
        )
    }
}
