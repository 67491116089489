import React, { Component } from 'react'
import { isvalidBrowser } from '../../helper/browserCheck'

export class BrowserBanner extends Component {
    state = {
        show_banner: false
    }
    componentDidMount() {
        this.setState({ show_banner: isvalidBrowser() ? false : true })
    }
    render() {
        const { show_banner } = this.state
        return (
            <div>
                {show_banner ?
                    ''
                    : <article className="panel is-info has-text-centered">
                        <p className="panel-heading has-text-weight-normal">
                            For best user experience, please use the latest version of Google Chrome.
                        </p>
                    </article>}
            </div>
        )
    }
}

export default BrowserBanner
