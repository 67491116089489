
export const isvalidBrowser = () => {
    let check = false;
    (function (a) {
        if ((a.indexOf('Chrome') > 0 || a.indexOf('Firefox') > 0 || a.indexOf('Safari') > 0) && a.indexOf('OPR') === -1 && a.indexOf('Edg') === -1) {
            check = false
        } else {
            check = true
        }
    })(navigator.userAgent);
    return check;
};